<template>
    <div>
        <div class="row mt-6">
            <div class="col">
                <h5 class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.BUILDINGS.TITLE') }}</h5>
                <div v-if="!item.buildings || !item.buildings.length">
                    {{ $t('REAL_ESTATE.DETAILS_MODAL.NO_DATA') }}
                </div>
            </div>
        </div>
        <div v-if="item.buildings && item.buildings.length">
            <st-section v-for="(building, index) in item.buildings" :key="index" :header="getBuildingHeader(building)" collapsible isCollapsed :collapsibleId="`details-buildings${index}`">
                <template #body>
                    <div class="row">
                        <div class="col">
                            <div>
                                <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.BUILDINGS.MEASUREAD_AREA') }}: </span>
                                <span>{{ building.measuread_area || ' - ' }}</span>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.BUILDINGS.LEGAL_AREA') }}: </span>
                                <span>{{ building.legal_area || ' - ' }}</span>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.BUILDINGS.USE_CATEGORY') }}: </span>
                                <span>{{ building.current_use ? building.current_use.name : ' - ' }}</span>
                            </div>
                            <div>
                                <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.BUILDINGS.LEGAL') }}: </span>
                                <span>{{ building.legal ? $t('GENERAL.YES') : $t('GENERAL.NO') }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </st-section>
        </div>
    </div>
</template>

<script>
export default {
    name: "RealEstateDetailsBuildings",
    props: {
        item: {
            type: Object,
            required: false,
        }, 
    },
    methods: {
        getBuildingHeader(building) {
            return this.$t('REAL_ESTATE.DETAILS_MODAL.BUILDINGS.HEADER', { number: building.electronic_identifier });
        },
    },
};
</script>