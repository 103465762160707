<template>
    <st-section :header="$t('REAL_ESTATE.DETAILS_MODAL.REGISTRATIONS.HEADER_SECOND')" collapsible isCollapsed collapsibleId="details-registrations-second">
        <template #body>
            <div v-for="(registration, index) in registrations" :key="index" class="row">
                <div class="col">
                    <div>
                        <span class="font-weight-bold">{{ getApplicationTitle(registration) }}</span>
                    </div>
                    <div v-for="(deed, i) in registration.deeds_list" :key="`${index}_${i}`">
                        <span>{{ getDeedText(deed) }};</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ getSection(registration) }}: </span>
                        <span>{{ getDescription(registration) }}</span>
                    </div>
                    <div v-for="(person, j) in registration.registration_persons_list" :key="`_${index}_${j}`">
                        <span>{{ `${j + 1}) ` }}</span>
                        <span class="font-weight-bold">
                            {{ getPersonText(person) }}
                        </span>
                        <span v-if="person.registration_person_comment">
                            {{ `, ${person.registration_person_comment}` }}
                        </span>
                    </div>
                    <div class="mb-4"></div>
                </div>
            </div>
            <div v-if="!registrations || !registrations.length">
                {{ $t('REAL_ESTATE.DETAILS_MODAL.NO_DATA') }}
            </div>
        </template>
    </st-section>
</template>

<script>
import moment from 'moment';

export default {
    name: "RealEstateDetailsRegistrationsSecond",
    props: {
        item: {
            type: Object,
            required: false,
        }, 
    },
    computed: {
        registrations() {
            return this.item.registrations.filter(item => item.status && item.landbook_section === 3)
        },
    },
    methods: {
        getApplicationTitle(registration) {
            return `${registration.application_no} / ${moment(registration.application_date).format('DD/MM/YYYY').toString()}`;
        },
        getDeedText(deed) {
            return `${deed.type?.name?.toUpperCase()} nr. ${deed.issue_number}, din ${moment(deed.issue_date).format('DD/MM/YYYY').toString()} emis de ${deed.issuer}`;
        },
        getSection(registration) {
            return `C${registration.landbook_section_position}`;
        },
        getDescription(registration) {
            if (registration.registration_type?.code === 'NOTATION') {
                return registration.note;
            }
            if (registration.registration_type?.code === 'INTAB') {
                return `Intabulare, drept de ${registration.right_type?.code || ''} ${registration.right_comment || ''}, dobandit prin ${registration.property_transferType?.code || ''}, cota actuala ${registration.actual_quota}`
            }
        },
        getPersonText(person) {
            return `${person.person_name} ${person.person_first_name}`;
        },
    }
};
</script>