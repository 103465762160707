<template>
    <st-modal
        id="real-estate-modal"
        hide-header-delimiter
        hide-footer-delimiter
        hide-footer
        hideable
        :noCloseOnBackdrop="true"
        size="lg"
        ref="modal"
        customClass="form-modal"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                {{ $t("REAL_ESTATE.MODAL.ADD_HEADER") }}
            </p>
        </template>
        <template #body>
            <real-estate-form
                :item="item"
                :cadastralNumber="cadastralNumber"
                @submitForm="submit"
                @cancelForm="cancel"
            ></real-estate-form>
        </template>
    </st-modal>
</template>

<script>
import RealEstateForm from "@/modules/applications/components/real-estate/RealEstateForm";

export default {
    name: "RealEstateModal",
    props: {
        item: {
            type: Object,
            required: false,
        },
        cadastralNumber: {
            type: Array
        }
    },
    components: {
        RealEstateForm,
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
        submit(data) {
            this.$emit("processRealEstate", data);
            this.hide();
        },
        cancel() {
            this.$emit("cancel");
            this.hide();
        },
    },
};
</script>
