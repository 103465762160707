<template>
    <st-section :header="$t('REAL_ESTATE.DETAILS_MODAL.GENERAL.HEADER')" collapsible collapsibleId="details-general">
        <template #body>
            <div class="row">
                <div class="col">
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.GENERAL.UAT') }}: </span>
                        <span>{{ uat ? uat : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.GENERAL.MEASUREAD_AREA') }}: </span>
                        <span>{{ measureadArea ? measureadArea : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.GENERAL.LEGAL_AREA') }}: </span>
                        <span>{{ legalArea ? legalArea : ' - ' }}</span>
                    </div>
                </div>
                <div class="col">
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.GENERAL.CADASTRAL_NUMBER') }}: </span>
                        <span>{{ cadastralNumber ? cadastralNumber : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.GENERAL.PREVIOUS_CAD_NO') }}: </span>
                        <span>{{ previousCadNo ? previousCadNo : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.GENERAL.PREVIOUS_LAND_BOOK_NO') }}: </span>
                        <span>{{ previousLandBookNo ? previousLandBookNo : ' - ' }}</span>
                    </div>
                </div>
            </div>
        </template>
    </st-section>
</template>

<script>
export default {
    name: "RealEstateDetailsGeneral",
    props: {
        item: {
            type: Object,
            required: false,
        }, 
    },
    computed: {
        uat() {
            return this.item?.territorial_administrative_unit;
        },
        cadastralNumber() {
            return this.item?.cadastral_number;
        },
        previousCadNo() {
            return this.item?.previous_cad_no;
        },
        previousLandBookNo() {
            return this.item?.previous_land_book_no;
        },
        measureadArea() {
            return this.item?.measuread_area;
        },
        legalArea() {
            return this.item?.legal_area;
        },
    }
};
</script>