<template>
    <div>
        <div v-if="item.parcels.length" class="row mt-6">
            <div class="col">
                <h5 class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.PARCELS.TITLE') }}</h5>
            </div>
        </div>
        <st-section v-for="(parcel, index) in item.parcels" :key="index" :header="getParcelHeader(parcel)" collapsible isCollapsed :collapsibleId="`details-parcels${index}`">
            <template #body>
                <div class="row">
                    <div class="col">
                        <div>
                            <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.PARCELS.PROPERTY_TITLE_NO') }}: </span>
                            <span>{{ parcel.property_title_no || ' - ' }}</span>
                        </div>
                        <div>
                            <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.PARCELS.MEASUREAD_AREA') }}: </span>
                            <span>{{ parcel.measuread_area || ' - ' }}</span>
                        </div>
                        <div>
                            <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.PARCELS.LEGAL_AREA') }}: </span>
                            <span>{{ parcel.legal_area || ' - ' }}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div>
                            <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.PARCELS.USE_CATEGORY') }}: </span>
                            <span>{{ parcel.use_category ? parcel.use_category.name : ' - ' }}</span>
                        </div>
                        <div>
                            <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.PARCELS.IS_AGRICULTURAL') }}: </span>
                            <span>{{ parcel.is_agriculural ? $t('GENERAL.YES') : $t('GENERAL.NO') }}</span>
                        </div>
                        <div>
                            <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.PARCELS.INTRAVILAN') }}: </span>
                            <span>{{ parcel.intravilan ? $t('GENERAL.YES') : $t('GENERAL.NO') }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </st-section>
    </div>
</template>

<script>
export default {
    name: "RealEstateDetailsParcels",
    props: {
        item: {
            type: Object,
            required: false,
        }, 
    },
    methods: {
        getParcelHeader(parcel) {
            return this.$t('REAL_ESTATE.DETAILS_MODAL.PARCELS.HEADER', { number: parcel.electronic_identifier });
        },
    },
};
</script>