<template>
    <div>
        <div class="row">
            <div class="col col-8">
            <div class="font-weight-bold">{{ item.cadastral_number }}</div>
            </div>
            <div class="col col-4 p-0" v-if="!viewMode">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('edit', {item, index})">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="card-action" @click="$emit('delete', {item, index})">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
            <div class="col col-4 p-0" v-if="canViewDetails">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('viewDetails', {item, index})">
                        <i class="fas fa-eye"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-8">{{ $t("REAL_ESTATE.CARD.ADDRESS_COUNTY", { county: item.county}) }}</div>
        <div>
            <span class="font-weight-bold">{{ $t("REAL_ESTATE.CARD.UAT") }}: </span>
            <span>{{ item.territorial_administrative_unit }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("REAL_ESTATE.CARD.CADASTRAL_NUMBER") }}: </span>
            <span>{{ item.cadastral_number }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("REAL_ESTATE.CARD.LAND_AREA") }}: </span>
            <span>{{ item.land_area }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("REAL_ESTATE.CARD.CATEGORY") }}: </span>
            <span>{{ item.category_code }}</span>
        </div>
        <div v-if="showMissingCoordinatesMessage">
            <span class="font-weight-bold text-warning">
                <i class="fas fa-info-circle mr-2"></i>{{ $t("REAL_ESTATE.CARD.MISSING_COORDINATES_MESSAGE") }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RealEstateItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        canViewDetails: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showMissingCoordinatesMessage() {
            return !this.item.coordinates?.length;
        }
    }
}
</script>
<style lang="">
    
</style>