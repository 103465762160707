<template>
    <div>
        <st-modal
            id="real-estate-view-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            hideable
            size="md"
            :noCloseOnBackdrop="true"
            ref="modal"
            customClass="form-modal view-modal"
        >
            <template #header>
                <div class="w-100 d-flex pb-2 border-bottom align-items-center pb-4">
                    <div class="col-md-7 px-0">
                        <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                            {{ modalTitle }}
                        </p>
                    </div>
                    <div class="col-md-5 px-0">
                        <div class="d-flex justify-content-end">
                            <div class="view-item arrow left" @click="goLeft">
                                <i class="fas fa-chevron-left"></i>
                            </div>
                            <div class="view-item middle">
                                Imobil {{ currentIndex + 1 }} din
                                {{ items.length }}
                            </div>
                            <div class="view-item arrow right" @click="goRight">
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #body>
                <real-estate-details :item="currentRealEstate"></real-estate-details>
                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="hide">
                        {{ $t("APPLICATION.MODALS.CANCEL") }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import RealEstateDetails from '@/modules/applications/components/view-application/RealEstateDetails';

export default {
    name: "RealEstateViewModal",
    components: {
        RealEstateDetails
    },
    props: {
        items: {
            type: Array,
            required: false,
        },
        index: {
            type: Number,
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    computed: {
        modalTitle() {
            if (this.currentRealEstate.real_estate_type === 'land') {
                return `${this.currentRealEstate.county}, ${this.currentRealEstate.street} ${this.currentRealEstate.street_number}`;
            } else {
                return `${this.currentRealEstate.postal_code} ${this.currentRealEstate.locality}`;
            }
        },
        currentRealEstate() {
            const realEstate = this.items[this.currentIndex];
            if (this.items?.identification_files) {
                realEstate.files = this.items.identification_files.filter((el) => el.cadastral_number === realEstate.cadastral_number);
            }
            
            return realEstate;
        },
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
        goRight() {
            if (this.currentIndex + 1 < this.items.length) {
                this.currentIndex = this.currentIndex + 1;
            } else {
                this.currentIndex = 0;
            }
        },
        goLeft() {
            if (this.currentIndex + 1 >= this.items.length - 1) {
                this.currentIndex = this.currentIndex - 1;
                if (this.currentIndex < 0) {
                    this.currentIndex = 0;
                }
            } else {
                this.currentIndex = this.items.length - 1;
            }
        },
    },
    mounted() {
        // Initialize the form after the modal is shown
        this.$root.$on("bv::modal::shown", (modalObj) => {
            if (modalObj.componentId === "real-estate-view-modal") {
                this.currentIndex = this.index;
            }
        });
    },
};
</script>
