<template>
    <st-section :header="$t('REAL_ESTATE.DETAILS_MODAL.OWNERS.HEADER')" collapsible isCollapsed collapsibleId="details-owners">
        <template #body>
            <div v-for="(owner, index) in item.owners" :key="index" class="row">
                <div class="col">
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.OWNERS.NAME') }}: </span>
                        <span>{{ `${owner.person_name} ${owner.person_first_name}` }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.OWNERS.CNP') }}: </span>
                        <span>{{ owner.national_code || ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.OWNERS.OWNERSHIP_TYPE') }}: </span>
                        <span>{{ owner.ownership_type ? owner.ownership_type.name : ' - ' }}</span>
                    </div>
                    <span>{{ $t('REAL_ESTATE.DETAILS_MODAL.OWNERS.DESCRIPTION', {
                        registration_type: owner.registration_type.name,
                        right_type: owner.right_type.name,
                        property_transfer_type: owner.property_transfer_type.name,
                        actual_quota: owner.actual_quota,
                    }) }}</span>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.OWNERS.IS_PART_HOLDER') }}: </span>
                        <span>{{ owner.is_part_holder ? $t('GENERAL.YES') : $t('GENERAL.NO') }}</span>
                    </div>
                    <div class="mb-4"></div>
                </div>
            </div>
            <div v-if="!item.owners || !item.owners.length">
                {{ $t('REAL_ESTATE.DETAILS_MODAL.NO_DATA') }}
            </div>
        </template>
    </st-section>
</template>

<script>
export default {
    name: "RealEstateDetailsOwners",
    props: {
        item: {
            type: Object,
            required: false,
        }, 
    },
};
</script>