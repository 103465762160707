<template>
    <st-modal
        id="real-estate-details-modal"
        hide-header-delimiter
        hide-footer-delimiter
        hide-footer
        hideable
        size="lg"
        ref="modal"
        customClass="form-modal"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                {{ $t("REAL_ESTATE.DETAILS_MODAL.HEADER", { date: updatedDate }) }}
            </p>
        </template>
        <template #body>
            <div class="row">
                <div class="col text-center">
                    <h4 class="font-weight-bold">{{ $t("REAL_ESTATE.DETAILS_MODAL.SUBHEADER_DESCRIPTION") }}</h4>
                </div>
            </div>
            <real-estate-details-general :item="item" />
            <real-estate-details-address :item="item" />
            <real-estate-details-parcels :item="item" />
            <real-estate-details-buildings :item="item" />
            <div class="row mt-6">
                <div class="col text-center">
                    <h4 class="font-weight-bold">{{ $t("REAL_ESTATE.DETAILS_MODAL.SUBHEADER_REGISTRATIONS_FIRST") }}</h4>
                </div>
            </div>
            <real-estate-details-owners :item="item" />
            <real-estate-details-registrations-first :item="item" />
            <div class="row mt-6">
                <div class="col text-center">
                    <h4 class="font-weight-bold">{{ $t("REAL_ESTATE.DETAILS_MODAL.SUBHEADER_REGISTRATIONS_SECOND") }}</h4>
                </div>
            </div>
            <real-estate-details-registrations-second :item="item" />

        </template>
    </st-modal>
</template>

<script>
import RealEstateDetailsGeneral from "@/modules/applications/components/real-estate/RealEstateDetailsGeneral";
import RealEstateDetailsAddress from "@/modules/applications/components/real-estate/RealEstateDetailsAddress";
import RealEstateDetailsParcels from "@/modules/applications/components/real-estate/RealEstateDetailsParcels";
import RealEstateDetailsBuildings from "@/modules/applications/components/real-estate/RealEstateDetailsBuildings";
import RealEstateDetailsOwners from "@/modules/applications/components/real-estate/RealEstateDetailsOwners";
import RealEstateDetailsRegistrationsFirst from "@/modules/applications/components/real-estate/RealEstateDetailsRegistrationsFirst";
import RealEstateDetailsRegistrationsSecond from "@/modules/applications/components/real-estate/RealEstateDetailsRegistrationsSecond";
import moment from 'moment';

export default {
    name: "RealEstateDetailsModal",
    props: {
        item: {
            type: Object,
            required: false,
        }, 
    },
    components: {
        RealEstateDetailsGeneral,
        RealEstateDetailsAddress,
        RealEstateDetailsParcels,
        RealEstateDetailsBuildings,
        RealEstateDetailsOwners,
        RealEstateDetailsRegistrationsFirst,
        RealEstateDetailsRegistrationsSecond,
    },
    computed: {
        updatedDate() {
            return moment(this.item.updated_date).format('DD.MM.YYYY HH:mm');
        },
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
    },
};
</script>
