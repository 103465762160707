<template>
    <st-section :header="$t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.HEADER')" collapsible isCollapsed collapsibleId="details-address">
        <template #body>
            <div class="row">
                <div class="col">
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.COUNTY') }}: </span>
                        <span>{{ county ? county : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.ADMIN_UNIT') }}: </span>
                        <span>{{ adminUnit ? adminUnit : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.LOCALITY') }}: </span>
                        <span>{{ locality ? locality : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.STREET_TYPE') }}: </span>
                        <span>{{ streetType ? streetType : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.STREET_NAME') }}: </span>
                        <span>{{ streetName ? streetName : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.POSTAL_NUMBER') }}: </span>
                        <span>{{ postalNumber ? postalNumber : ' - ' }}</span>
                    </div>
                </div>
                <div class="col">
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.BUILDING_NUMBER') }}: </span>
                        <span>{{ buildingNumber ? buildingNumber : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.BUILDING_PART_NUMBER') }}: </span>
                        <span>{{ buildingPartNumber ? buildingPartNumber : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.FLOOR_NUMBER') }}: </span>
                        <span>{{ floorNumber ? floorNumber : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.BUILDING_UNIT_NUMBER') }}: </span>
                        <span>{{ buildingUnitNumber ? buildingUnitNumber : ' - ' }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">{{ $t('REAL_ESTATE.DETAILS_MODAL.ADDRESS.POSTAL_CODE') }}: </span>
                        <span>{{ postalCode ? postalCode : ' - ' }}</span>
                    </div>
                </div>
            </div>
        </template>
    </st-section>
</template>

<script>
export default {
    name: "RealEstateDetailsAddress",
    props: {
        item: {
            type: Object,
            required: false,
        }, 
    },
    computed: {
        county() {
            return this.item?.postal_address?.county?.name;
        },
        adminUnit() {
            return this.item?.postal_address?.admin_unit?.name;
        },
        locality() {
            return this.item?.postal_address?.locality?.name;
        },
        streetType() {
            return this.item?.postal_address?.street_type?.name;
        },
        streetName() {
            return this.item?.postal_address?.street_name?.name;
        },
        postalNumber() {
            return this.item?.postal_address?.postal_number?.name;
        },
        buildingNumber() {
            return this.item?.postal_address?.building_number?.name;
        },
        buildingPartNumber() {
            return this.item?.postal_address?.building_part_number?.name;
        },
        floorNumber() {
            return this.item?.postal_address?.floor_number?.name;
        },
        buildingUnitNumber() {
            return this.item?.postal_address?.building_unit_number?.name;
        },
        postalCode() {
            return this.item?.postal_address?.postal_code?.name;
        },
    }
};
</script>